@import "~bootswatch/dist/journal/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/journal/bootswatch";

$navbar-height: 59px;

$navbar-background: theme-color('light');
.navbar {
    @extend .navbar-light;
    background-color: $navbar-background;
}

$callstatus-foreground: theme-color('dark');

@import '../main.scss';
